<template>
  <div class="app-body">
    <div class="a-flex-rsbc a-ptb-15">
      <span class="a-fs-16 a-fw-b">提现管理</span>
      <div v-if="false" class="a-flex-rcc">
        <el-button icon="el-icon-upload2" class="s-btn-exp">导出</el-button>
      </div>
    </div>
    <el-card class="el-main">
      <le-search-form @reset="handlerRest" @search="handlerSearch">
        <le-input-icon-search
          v-model="pageParam.params.searchKey"
          label="商户名称"
          placeholder="请输入商户名称"
        />
        <le-select-local-search
          v-model="pageParam.params.status"
          label="提现状态"
          :options="optionsProject"
        />
      </le-search-form>
      <le-pagview :page-param="pageParam" :total.sync="total" @setData="setTableData">
        <el-table :data="tableData" :highlight-current-row="true">
          <el-table-column prop="companyName" label="商户名称" min-width="150"></el-table-column>
          <el-table-column prop="applyUserName" label="申请人"></el-table-column>
          <el-table-column prop="createTimeText" label="申请时间" min-width="120"></el-table-column>
          <el-table-column
            prop="payoutMethodText"
            label="提现方式"
            min-width="120"
          ></el-table-column>
          <el-table-column prop="amount" label="提现金额(元)" min-width="150">
            <template slot-scope="{ row }">
              <span>{{ "￥" + config.numFormat(row.amount) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="审核状态" min-width="150">
            <template slot-scope="{ row }">
              <span v-if="row.state == 0" class="stateClass0">{{ row.stateText }}</span>
              <span v-if="row.state == 1" class="stateClass0">{{ row.stateText }}</span>
              <span v-if="row.state == 2" class="stateClass2">{{ row.stateText }}</span>
              <span v-if="row.state == 3" class="stateClass3">{{ row.stateText }}</span>
              <span v-if="row.state == 4" class="stateClass4">{{ row.stateText }}</span>
              <span v-if="row.state == 5" class="stateClass4">{{ row.stateText }}</span>
              <span v-if="row.state == 6" class="stateClass3">{{ row.stateText }}</span>
              <!-- 0:待审核,1:待付款,2:成功,3:关闭,4:异常 5:审核不通过 -->
            </template>
          </el-table-column>
          <el-table-column prop="name" label="操作" width="80" fixed="right">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" content="详情" placement="top">
                <img
                  src="../../assets/icon/option-detail.png"
                  class="a-wh-16 mgr20"
                  @click="toWithdrawalDetail(scope.row)"
                />
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <template slot="footerLabel" slot-scope="scope">
          <div v-if="scope.val != -1" class="a-flex-rfsc">
            <span class="a-fs-12 a-c-normal">共</span>
            <span class="a-fs-16 a-c-master a-fw-b a-plr-7">{{ total }}</span>
            <span class="a-fs-12 a-c-normal">名员工</span>
          </div>
        </template>
      </le-pagview>
    </el-card>
  </div>
</template>

<script>
import config from "../../../src/utils/util";
export default {
  name: "WithdrawalView",

  data() {
    return {
      config: config, //公共方法集

      pageParam: {
        url: this.$Config.apiUrl.getWithdrawManagerList,
        method: "post",
        params: {
          searchKey: "", //加盟商名称
          status: "", //
          // companyId: '',//所属商户
        },
        freshCtrl: 1,
      },
      optionsProject: [
        { label: "待审核", value: 0 },
        { label: "待支付", value: 1 },
        { label: "已完成", value: 2 },
        { label: "已关闭", value: 3 },
        { label: "异常", value: 4 },
      ],
      tableData: [], //数据列表
      total: 0,
    };
  },
  created() {},
  methods: {
    //获取列表
    setTableData(data) {
      this.tableData = data;
    },
    //确认筛选
    handlerSearch() {
      this.pageParam.freshCtrl++;
    },
    //重置筛选表单
    handlerRest() {
      this.pageParam.params = {
        searchKey: "", //名称
        companyId: "", //所属商户
      };
    },
    companyBack() {},
    //跳转详情
    toWithdrawalDetail(datas) {
      this.$router.push({
        path: `/withdrawal/withdrawal-detail?code=${datas.id}`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.mgl150 {
  margin-left: 150px;
}
.stateClass0 {
  display: inline-block;
  background: #e6f1ff;
  color: #007aff;
  font-size: 14px;
  padding: 2px 8px;
  border-radius: 4px;
}
.stateClass2 {
  display: inline-block;
  background: #e6ffe6;
  color: #29cc29;
  font-size: 14px;
  padding: 2px 8px;
  border-radius: 4px;
}
.stateClass3 {
  display: inline-block;
  background: #ebebeb;
  color: #606366;
  font-size: 14px;
  padding: 2px 8px;
  border-radius: 4px;
}
.stateClass4 {
  display: inline-block;
  background: #ffeded;
  color: #ff3b30;
  font-size: 14px;
  padding: 2px 8px;
  border-radius: 4px;
}
</style>
